import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// Styles
import styles from "../sass/pages/contact.module.scss"

let lang = "";
let labelLang = "";

const goToURL = (e: any, url: string) => {
  window.location.assign(url)
  e.preventDefault()
}
const Contact = () => {
  const title = useIntl().formatMessage({ id: 'contact' });
  lang = useIntl().locale
  return (
    <Layout>
      <SEO title={title} />
      <article className="container-small">
        <article className="section-fade">
          <div className="space"></div>
          <h2 className={styles.h2}><FormattedMessage id="contact_title_1"/></h2>
          <div className={'grid-cards-2 '}>
            <div>
              <div className="space"></div>
              <h3><FormattedMessage id="contact_title_2"/></h3>
              <p><FormattedMessage id="contact_paragraph_1"/></p>
              
              <Link to={`/${lang}` + "/join-us"}>
              <button className="btn btn-main "><FormattedMessage id="join_us" /></button>
              </Link>
              
            </div>

            <div>
              <div className="space"></div>
              <h3><FormattedMessage id="contact_title_3"/></h3>
              <p><FormattedMessage id="contact_paragraph_2"/></p><br/>
              <Link to={`/${lang}` + "/hire-us"}>
              <button className="btn btn-main "><FormattedMessage id="hire_us" /></button>
              </Link>
            </div>
          </div>
        </article>
        <div className="spaceLarge"></div>
      </article>
    </Layout>
  )
}
export default Contact
